import * as React from "react"

export function Kontakt() {
  return (
    <>
      <p className="fw-bold fst-italic mt-5">Kontakt:</p>
<p>
    Verbindungsraum – professionelle Lebensberatung und Energiearbeit/-coaching
    <br/>
    Öffnungszeiten nach Terminvereinbarung!
</p>
<p>
    Sabrina Lackner
</p>
<p>
    Life Coach / Psychologische Beraterin
</p>
<p>
    Zertifizierte Organetikerin
</p>
<p>
    Energiecoach<br />
    New Spirit "Energy Consultant" & Elevation Coach
</p>
<p>
    Achenseestraße 67a (stundenweise vor Ort - Parkmöglichkeiten vorhanden!) und Tratzbergstrasse
</p>
<p>
    6200 Jenbach/Tirol
</p>
<p>
    Tel.: +43 664 350 5131
</p>
<p>
Mail:    <a href="mailto:sabrina.lackner1@gmail.com">sabrina.lackner1@gmail.com</a>
</p>
    </>
  )
}
